import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import {
    Switch,
    Route,
    BrowserRouter as Router,
} from 'react-router-dom';
import moment from 'moment';
import jwt_decode from 'jwt-decode'
import Home from './Components/Home';
import TagManager from 'react-gtm-module';
import Service from './Components/Service';
import { createBrowserHistory } from 'history';

import './App.css';
import AuthError from './Components/AuthError';

const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.set({ page: location.pathname + location.hash });
    ReactGA.pageview(location.pathname + location.hash);
});

const tagManagerArgs = {
    // eslint-disable-next-line no-undef
    gtmId: process.env.REACT_APP_TAG_MANAGER_CONTAINER
}

function App() {

    const [token, setToken] = useState(null)

    const [userData, setUserData] = useState({
        iat: null,
        name: null,
        email: null,
        level: null,
        expiresIn: null,
        external_user_id: null
    })

    useEffect(() => {
        getToken()
        ReactGA.pageview(window.location.pathname + window.location.hash);
    }, [])

    const getToken = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            try {
                const data = jwt_decode(token)
                const userName = data.name || data.nome || ''
                let isTokenValid = false

                if (
                    (data.email || userName || data.level) &&
                    data.external_user_id
                ) {
                    if(data.iat && (data.expires_in || data.expiresIn)) {
                        // if there is a token expiration date, validate it
                        const current = moment();
                        const expiresDate = moment(data.iat*1000).add(data.expires_in || data.expiresIn || '', 's')

                        if (!current.isAfter(expiresDate)) {
                            data.nickname = userName.split(' ')[0].toLowerCase()
                            isTokenValid = true
                        }
                    } else {
                        // if there is no token expiration date, only checks for email, name, level or external user_id
                        isTokenValid = true
                    }                   
                }
                isTokenValid=true;

                if(isTokenValid) {
                    setToken(token)
                    setUserData(data)
                    TagManager.initialize({
                        ...tagManagerArgs,
                        dataLayer: {
                            event: 'usuario',
                            usuario: data,
                        }
                    })
                    if (window.location.pathname.replace('/', '') !== '') {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'jornada',
                                jornada: window.location.pathname.replace('/', '')
                            }
                        })
                    } else {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'jornada',
                                jornada: 'home'
                            }
                        })
                    }
                }
            } catch (error) {
                console.log('Token inválido')
            }
        } else {
            console.log('Token não encontrado')
        }
    }

    return (
        token ? (
            <Router>
                {(userData) && (
                    <Switch>
                        <Route path={'/consultas'}>
                            <Service
                                type={1}
                                token={token}
                                userData={userData}
                            />
                        </Route>
                        <Route path={'/exames'}>
                            <Service
                                type={2}
                                token={token}
                                userData={userData}
                            />
                        </Route>
                        <Route path={'/'}>
                            <Home
                                token={token}
                                userData={userData}
                            />
                        </Route>
                    </Switch>
                )}
            </Router>
        ) : (
            <AuthError />
        )
    )
}

export default App;
